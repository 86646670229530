import {
  all,
  call,
  put,
  takeEvery,
  select,
  delay
} from 'redux-saga/effects';
import moment from 'moment';
import * as actions from './actions';
import * as actionTypes from './types';
import {
  get, post, apiDelete, put as apiPut, put as axiosPut,
  getFile
} from '../../utils/api';
import { popupLoaderClose, popupLoaderOpen } from '../loader/actions';
import { errorModal, openNotificationWithIcon, randomCode } from '../../utils/function';
import {
  VOTES,
  VOTE,
  VOTE_CANDIDATES,
  VOTE_CANDIDATES_ON_UUID,
  VOTE_PUSH,
  EVENT_REPORTS,
  EVENT_REPORT, IMAGES_EVENTS, VOTE_CANDIDATE_UUIDS_ON_UUID
} from '../../utils/url';
import { GET_DATA_CANDIDATE_UUIDS_EVENT_VOTE_UUID } from './types';
import { getDataCandidateUuidsEventVoteUuidSuccess } from './actions';

/**
 *  Получение списка мероприятий авторизированного администратора
 *  */
function* getListEventVotes({ payload }) {
  try {
    yield put(popupLoaderOpen());
    const response = yield call(
      get,
      VOTES
    );
    yield put(actions.getListEventVotesSuccess(response));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

/**
 * Получить данные по мероприятию
 * */
function* getDataEventVote({ payload }) {
  try {
    yield put(popupLoaderOpen());
    const response = yield call(
      get,
      `${VOTE}/${payload.voteUUID}`
    );
    yield put(actions.getDataEventVoteSuccess(response));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

/**
 * Получение кандидатов мероприятия
 * */
function* getDataCandidatesEventVoteUuid({ payload }) {
  try {
    yield put(popupLoaderOpen());
    const response = yield call(
      get,
      // eslint-disable-next-line max-len
      `${VOTE_CANDIDATES_ON_UUID(payload.uuid)}?offset=${payload.offset}&limit=${payload.limit}`
    );
    yield put(actions.getDataCandidatesEventVoteUuidSuccess({
      ...response,
      limit: payload.limit
    }));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

/**
 * Получение полного списка идентификаторов кандидатов мероприятия
 * */
function* getDataCandidateUuidsEventVoteUuid({ payload }) {
  try {
    yield put(popupLoaderOpen());
    const response = yield call(
      get,
      // eslint-disable-next-line max-len
      `${VOTE_CANDIDATE_UUIDS_ON_UUID(payload.uuid)}`
    );
    yield put(actions.getDataCandidateUuidsEventVoteUuidSuccess({
      ...response
    }));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* updateGetDataCandidatesEventVoteUuid({ uuid }) {
  const { voteCandidatesOnEvent } = yield select(({ eventReducer }) => eventReducer);
  yield put(actions.getDataCandidatesEventVoteUuid({
    uuid,
    offset: (
      (voteCandidatesOnEvent.pagination.current ? voteCandidatesOnEvent.pagination.current : 1) - 1)
      * voteCandidatesOnEvent.pagination.pageSize,
    limit: voteCandidatesOnEvent.pagination.pageSize
  }));
}

/**
 * Получение кандидатов по организации
 * */
function* getDataCandidatesOnOrganizationEventVote({ payload }) {
  try {
    yield put(popupLoaderOpen());
    let response;
    if (payload.organizationUuid) {
      response = yield call(
        get,
        // eslint-disable-next-line max-len
        `${VOTE_CANDIDATES}?organizationUUID=${payload.organizationUuid}&offset=${payload.offset}&limit=${payload.limit}${payload.search ? `&search=${encodeURI(payload.search)}` : ''}`
      );
    } else {
      response = yield call(
        get,
        // eslint-disable-next-line max-len
        `${VOTE_CANDIDATES}?offset=${payload.offset}&limit=${payload.limit}${payload.search ? `&search=${encodeURI(payload.search)}` : ''}`
      );
    }
    yield put(actions.getDataCandidatesOnOrganizationEventVoteSuccess({
      ...response,
      limit: payload.limit,
      search: payload.search
    }));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

/**
 * Создание мероприятия
 * */
function* createEvent({ payload }) {
  // данные по умолчанию, для создания мероприятия
  const tempData = new Date();
  const data = {
    type: 'OPEN',
    title: payload.title,
    countLike: 0,
    beginAt: moment(new Date(tempData.getFullYear(), tempData.getMonth(), tempData.getDate(), 0, 0, 0, 0)).valueOf(),
    endAt: null,
    inviteCode: randomCode(),
    reasons: [],
    candidates: []
  };
  try {
    yield put(popupLoaderOpen());
    const response = yield call(
      post,
      VOTE,
      data
    );
    yield put(actions.getListEventVotes()); // зарпросить новый список мероприятий
    yield put(actions.getDataEventVoteSuccess(response));
    payload.setNameEvent(null); // обнуляем input
    yield put(popupLoaderClose());
    // payload.selectEventRef.current.blur();
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

/**
 * Редактирование мероприятия
 * */
function* editEvent({ payload }) {
  try {
    yield put(popupLoaderOpen());

    let beginAt = moment(payload.beginAt);
    beginAt.hour(0);
    beginAt.minute(0);
    beginAt.second(0);
    beginAt.millisecond(0);
    beginAt = beginAt.valueOf();

    let endAt = null;
    if (payload.endAt) {
      endAt = moment(payload.endAt);
      endAt.hour(23);
      endAt.minute(59);
      endAt.second(59);
      endAt.millisecond(999);
      endAt = endAt.valueOf();
    }

    yield call(
      apiPut,
      `${VOTE}/${payload.uuid}`,
      {
        type: payload.type,
        title: payload.title,
        countLike: payload.countLike,
        beginAt,
        endAt,
        text: payload.text,
        imageUrl: payload.imageUrl,
        inviteCode: payload.inviteCode,
        reasons: payload.reasons,
        candidates: payload.candidates ? payload.candidates : []
      }
    );
    yield put(actions.getDataEventVote({
      voteUUID: payload.uuid
    }));
    if (payload.candidates) {
      yield all([updateGetDataCandidatesEventVoteUuid({ uuid: payload.uuid })]);
    }
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

/**
 * Удаление мероприятия
 * */
function* deleteEvent({ payload }) {
  try {
    yield put(popupLoaderOpen());
    yield call(
      apiDelete,
      `${VOTE}/${payload.uuid}`
    );
    yield put(popupLoaderClose());
    document.location.href = '/event-management';
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

/** данные по push */
function* getPushEvent({ payload }) {
  try {
    yield put(popupLoaderOpen());
    const response = yield call(
      get,
      `${VOTE_PUSH}/${payload.uuid}`
    );
    yield put(actions.getPushEventSuccess(response));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* editPushEvent({ payload }) {
  try {
    yield put(popupLoaderOpen());
    yield call(
      axiosPut,
      `${VOTE_PUSH}/${payload.uuid}`,
      {
        ...payload.data
      }
    );
    yield put(actions.getPushEvent({ uuid: payload.uuid }));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* deleteUserOnEvent({ payload }) {
  try {
    yield put(popupLoaderOpen());
    const { selectEvent } = yield select(({ eventReducer }) => eventReducer);
    yield call(
      apiDelete,
      `${VOTE}/${selectEvent}/candidate/${payload.uuid}`
    );
    yield all([updateGetDataCandidatesEventVoteUuid({ uuid: selectEvent })]);
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* getListEventReports({ payload }) {
  try {
    yield put(popupLoaderOpen());
    const response = yield call(
      get,
      EVENT_REPORTS(payload)
    );
    yield put(actions.getListEventReportsSuccess(response));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* getCreateEventReport({ payload }) {
  try {
    yield put(popupLoaderOpen());

    // eslint-disable-next-line no-underscore-dangle
    let beginAt = moment(payload.beginAt._d.getTime());
    beginAt.hour(0);
    beginAt.minute(0);
    beginAt.second(0);
    beginAt.millisecond(0);
    beginAt = beginAt.valueOf();

    let endAt = null;
    // eslint-disable-next-line no-underscore-dangle
    if (payload.endAt._d.getTime()) {
      endAt = moment(payload.endAt);
      endAt.hour(23);
      endAt.minute(59);
      endAt.second(59);
      endAt.millisecond(999);
      endAt = endAt.valueOf();
    }

    const response = yield call(
      post,
      EVENT_REPORT(payload.uuid, payload.code),
      {
        beginAt,
        endAt
      }
    );
    let fileName;
    let fileBlob;
    let temp = true;
    let i = 0;
    while (temp && i < 30) {
      yield delay(response.retryAfter * 1000);
      try {
        [fileName, fileBlob] = yield call(getFile, response.location);
        temp = false;
      } catch (e) {
        if (e?.response?.status !== 425) {
          temp = false;
          yield put(popupLoaderClose());
          errorModal(
            e?.response?.data?.error,
            e?.response?.data?.message,
            e?.response?.data?.data
          );
        }
      }
      i += 1;
    }
    // const blob = new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
    // eslint-disable-next-line no-debugger
    // debugger;

    // saveAs(file);
    // window.open(URL.createObjectURL(blob));

    // ---
    const fileLink = document.createElement('a');
    fileLink.download = fileName;
    fileLink.href = URL.createObjectURL(fileBlob);
    fileLink.click();
    // ---
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

/**
 * Получить картинки по мероприятию
 * */
function* getImagesEvents() {
  try {
    yield put(popupLoaderOpen());
    const response = yield call(
      get,
      IMAGES_EVENTS
    );
    yield put(actions.getImagesEventsSuccess(response));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

// eslint-disable-next-line
export default function* () {
  yield all([
    takeEvery(actionTypes.GET_LIST_EVENT_VOTES, getListEventVotes),
    takeEvery(actionTypes.GET_DATA_EVENT_VOTE, getDataEventVote),
    takeEvery(actionTypes.GET_DATA_CANDIDATES_EVENT_VOTE_UUID, getDataCandidatesEventVoteUuid),
    takeEvery(actionTypes.GET_DATA_CANDIDATE_UUIDS_EVENT_VOTE_UUID, getDataCandidateUuidsEventVoteUuid),
    takeEvery(actionTypes.GET_DATA_CANDIDATES_ON_ORGANIZATION_EVENT_VOTE, getDataCandidatesOnOrganizationEventVote),
    takeEvery(actionTypes.CREATE_EVENT, createEvent),
    takeEvery(actionTypes.DELETE_EVENT, deleteEvent),
    takeEvery(actionTypes.EDIT_EVENT, editEvent),
    takeEvery(actionTypes.GET_PUSH_EVENT, getPushEvent),
    takeEvery(actionTypes.EDIT_PUSH_EVENT, editPushEvent),
    takeEvery(actionTypes.DELETE_USER_ON_EVENT, deleteUserOnEvent),
    takeEvery(actionTypes.GET_LIST_EVENT_REPORTS, getListEventReports),
    takeEvery(actionTypes.GET_CREATE_EVENT_REPORT, getCreateEventReport),
    takeEvery(actionTypes.GET_IMAGES_EVENTS, getImagesEvents)
  ]);
}
