import * as actionTypes from './types';

const initialState = {
  selectEvent: null,
  selectOrganization: null,
  pushData: null,
  dataSelectEvent: {
    uuid: null,
    countLike: null,
    beginAt: null,
    endAt: null
  },
  listVotes: [],
  voteCandidatesOnOrganization: {
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      search: null
    }
  },
  voteCandidatesOnEvent: {
    data: [],
    pagination: {
      current: 1,
      pageSize: 3,
      total: 0
    }
  },
  voteCandidateUuidsOnEvent: [],
  images: null
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_LIST_EVENT_VOTES_SUCCESS:
      return {
        ...state,
        listVotes: payload
      };
    case actionTypes.GET_DATA_EVENT_VOTE_SUCCESS:
      return {
        ...state,
        dataSelectEvent: payload,
        selectEvent: payload.uuid
      };
    case actionTypes.SET_SELECT_EVENT:
      return {
        ...state,
        selectEvent: payload
      };
    case actionTypes.SET_SELECT_EVENT_ORGANIZATION:
      return {
        ...state,
        selectOrganization: payload
      };
    case actionTypes.GET_DATA_CANDIDATES_EVENT_VOTE_UUID_SUCCESS:
      return {
        ...state,
        voteCandidatesOnEvent: {
          ...state.voteCandidatesOnEvent,
          data: payload.rows,
          pagination: {
            ...state.voteCandidatesOnEvent.pagination,
            pageSize: payload.limit,
            current: payload.current,
            total: payload.count
          }
        }
      };
    case actionTypes.GET_DATA_CANDIDATES_ON_ORGANIZATION_EVENT_VOTE_SUCCESS:
      return {
        ...state,
        voteCandidatesOnOrganization: {
          ...state.voteCandidatesOnOrganization,
          data: payload.rows,
          pagination: {
            ...state.voteCandidatesOnOrganization.pagination,
            pageSize: payload.limit,
            current: payload.current,
            total: payload.count,
            search: payload.search
          }
        }
      };
    case actionTypes.GET_DATA_CANDIDATE_UUIDS_EVENT_VOTE_UUID_SUCCESS:
      return {
        ...state,
        voteCandidateUuidsOnEvent: Object.keys(payload).map((key) => payload[key].uuid)
      };
    case actionTypes.SET_DATA_EDIT_EVENT:
      return {
        ...state,
        dataSelectEvent: {
          ...state.dataSelectEvent,
          ...payload,
          isEdit: true
        }
      };
    case actionTypes.GET_PUSH_EVENT_SUCCESS:
      return {
        ...state,
        pushData: payload
      };
    case actionTypes.GET_LIST_EVENT_REPORTS_SUCCESS:
      return {
        ...state,
        listDataReports: payload
      };
    case actionTypes.GET_IMAGES_EVENTS_SUCCESS: {
      return {
        ...state,
        images: payload
      };
    }
    default:
      return state;
  }
};

export default reducer;
