import { connect } from 'react-redux';
import TabEvent from './TabEvent';
import {
  editEvent,
  getDataCandidatesEventVoteUuid,
  getDataCandidateUuidsEventVoteUuid,
  getDataCandidatesOnOrganizationEventVote,
  setDataEditEvent
} from '../../store/event/actions';
import {
  getListOrganizations
} from '../../store/organization/actions';

const mapStateToProps = ({
  eventReducer,
  organizationReducer: {
    listData
  }
}) => ({
  listData,
  dataSelectEvent: eventReducer.dataSelectEvent,
  voteCandidatesOnEvent: eventReducer.voteCandidatesOnEvent,
  voteCandidateUuidsOnEvent: eventReducer.voteCandidateUuidsOnEvent,
  voteCandidatesOnOrganization: eventReducer.voteCandidatesOnOrganization,
  selectEvent: eventReducer.selectEvent
});
const mapDispatchToProps = {
  getDataCandidatesEventVoteUuid,
  getDataCandidateUuidsEventVoteUuid,
  getDataCandidatesOnOrganizationEventVote,
  getListOrganizations,
  setDataEditEvent,
  editEvent
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TabEvent);
