export const GET_LIST_EVENT_VOTES = 'GET_LIST_EVENT_VOTES';
export const GET_LIST_EVENT_VOTES_SUCCESS = 'GET_LIST_EVENT_VOTES_SUCCESS';

export const GET_DATA_EVENT_VOTE = 'GET_DATA_EVENT_VOTE';
export const GET_DATA_EVENT_VOTE_SUCCESS = 'GET_DATA_EVENT_VOTE_SUCCESS';

// eslint-disable-next-line max-len
export const GET_DATA_CANDIDATES_ON_ORGANIZATION_EVENT_VOTE = 'GET_DATA_CANDIDATES_ON_ORGANIZATION_EVENT_VOTE';
// eslint-disable-next-line max-len
export const GET_DATA_CANDIDATES_ON_ORGANIZATION_EVENT_VOTE_SUCCESS = 'GET_DATA_CANDIDATES_ON_ORGANIZATION_EVENT_VOTE_SUCCESS';

export const GET_DATA_CANDIDATES_EVENT_VOTE_UUID = 'GET_DATA_CANDIDATES_EVENT_VOTE_UUID';
export const GET_DATA_CANDIDATES_EVENT_VOTE_UUID_SUCCESS = 'GET_DATA_CANDIDATES_EVENT_VOTE_UUID_SUCCESS';
export const GET_DATA_CANDIDATE_UUIDS_EVENT_VOTE_UUID = 'GET_DATA_CANDIDATE_UUIDS_EVENT_VOTE_UUID';
export const GET_DATA_CANDIDATE_UUIDS_EVENT_VOTE_UUID_SUCCESS = 'GET_DATA_CANDIDATE_UUIDS_EVENT_VOTE_UUID_SUCCESS';

export const SET_SELECT_EVENT = 'SET_SELECT_EVENT';
export const SET_SELECT_EVENT_ORGANIZATION = 'SET_SELECT_EVENT_ORGANIZATION';

// создание мероприятия
export const CREATE_EVENT = 'CREATE_EVENT';

// удаление мероприятия
export const DELETE_EVENT = 'DELETE_EVENT';

// редактирование мероприятия
export const EDIT_EVENT = 'EDIT_EVENT';

// событие по изменению данных по мероприятию
export const SET_DATA_EDIT_EVENT = 'SET_DATA_EDIT_EVENT';

/** получение настроек уведомлений */
export const GET_PUSH_EVENT = 'GET_PUSH_EVENT';
export const GET_PUSH_EVENT_SUCCESS = 'GET_PUSH_EVENT_SUCCESS';
export const EDIT_PUSH_EVENT = 'EDIT_PUSH_EVENT';

// удаление пользователя из мероприятия
export const DELETE_USER_ON_EVENT = 'DELETE_USER_ON_EVENT';

export const GET_LIST_EVENT_REPORTS = 'GET_LIST_EVENT_REPORTS';
export const GET_LIST_EVENT_REPORTS_SUCCESS = 'GET_LIST_EVENT_REPORTS_SUCCESS';
export const GET_LIST_EVENT_REPORTS_FAIL = 'GET_LIST_EVENT_REPORTS_FAIL';
export const GET_CREATE_EVENT_REPORT = 'GET_CREATE_EVENT_REPORT';

export const GET_STATIC_EVENT = 'GET_STATIC_EVENT';
export const GET_STATIC_EVENT_SUCCESS = 'GET_STATIC_EVENT_SUCCESS';
export const GET_STATIC_EVENT_FAIL = 'GET_STATIC_EVENT_FAIL';

export const GET_IMAGES_EVENTS = 'GET_IMAGES_EVENTS';
export const GET_IMAGES_EVENTS_SUCCESS = 'GET_IMAGES_EVENTS_SUCCESS';
export const GET_IMAGES_EVENTS_FAIL = 'GET_IMAGES_EVENTS_FAIL';
