export const LOGIN = '/api/web/v1/login';
export const CHECK = '/api/web/v1/check';

export const ANALYTICS = '/api/web/analytics/v1';
export const ANALYTICS_BI = '/api/analytics/v1/bi';

export const ORGANIZATION = '/api/web/v1/organization';
export const ORGANIZATION_ON_UUID = (uuid) => `${ORGANIZATION}/${uuid}`;
export const ORGANIZATION_ON_UUID_ACCEPT_ALL = (uuid) => `${ORGANIZATION}/${uuid}/accept_all`;
export const ORGANIZATION_ON_UUID_STATISTICS = (uuid) => `${ORGANIZATION}/${uuid}/statistics`;
export const ORGANIZATION_ON_UUID_ANALYTICS_BI = (uuid) => `${ANALYTICS_BI}/${uuid}`;
export const ORGANIZATIONS = '/api/web/v1/organizations';

export const ORGANIZATION_REPORTS = (uuid) => `${ORGANIZATION}/${uuid}/reports`;
export const ORGANIZATION_REPORT = (uuid, codeOrUuid) => `${ORGANIZATION}/${uuid}/report/${codeOrUuid}`;

export const ORGANIZATION_PUSH = '/api/web/v1/push/organization';

export const UNASSIGNED_USERS = '/api/web/v1/unassigned-users';
export const UNASSIGNED_USER_ORGANIZATIONS = '/api/web/v1/unassigned-user/organizations';

export const UNASSIGNED_USER_ON_ORGANIZATION = (
  userUuid,
  organizationUuid
) => `/api/web/v1/unassigned-user/${userUuid}/organization/${organizationUuid}`;

export const UNASSIGNED_USER_DELETE = (
  userUuid
) => `/api/web/v1/unassigned-user/${userUuid}`;

export const INTERNAL_VOTE = (
  organizationUuid
) => `/api/web/v1/internal-vote/${organizationUuid}`;

export const INTERNAL_VOTE_STOP = (
  organizationUuid
) => `/api/web/v1/internal-vote/${organizationUuid}/completed`;

export const EMPLOYEES = (organizationUuid) => `/api/web/v1/employees/${organizationUuid}`;
export const EMPLOYEE = (organizationUuid) => `/api/web/v1/employee/${organizationUuid}`;
export const EMPLOYEE_ON_USER = (
  userUuid,
  organizationUuid
) => `${EMPLOYEE(organizationUuid)}/${userUuid}`;

export const VOTE = '/api/web/v1/vote';
export const VOTES = '/api/web/v1/votes';
export const VOTE_CANDIDATES = `${VOTE}/candidates`;
export const VOTE_CANDIDATES_ON_UUID = (voteUUID) => `${VOTE}/${voteUUID}/candidates`;
export const VOTE_CANDIDATE_UUIDS_ON_UUID = (voteUUID) => `${VOTE}/${voteUUID}/candidates/full`;

export const EVENT_REPORTS = (uuid) => `${VOTE}/${uuid}/reports`;
export const EVENT_REPORT = (uuid, codeOrUuid) => `${VOTE}/${uuid}/report/${codeOrUuid}`;

export const VOTE_PUSH = '/api/web/v1/push/vote';

export const ADMINS = '/api/web/v1/admins';
export const ADMIN = '/api/web/v1/admin';
export const ROLES = '/api/web/v1/roles';

export const IMPORT_PERSON_ON_CSV = '/api/web/v1/import';

export const IMAGES_EVENTS = '/pictures/events';
